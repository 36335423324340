import React from 'react'
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import Styles from '../../css/styles.module.css';

const IntroductionSection = styled.section`
    background-color:  #ffffff;
`;

const IntroductionWrapper = styled.div`
    padding: 50px 30px;
`;

const IntroductionTitle = styled.div`
`;
const IntroductionBody = styled.div`
`;


const IntroductionNavLink = styled(Link)`
    
`;

const Introduction = () => {
    return (
    <>
        <IntroductionSection className={Styles.standardSection}>
            <IntroductionWrapper className={Styles.standardWrapper}>
                <IntroductionTitle className={Styles.standardTitle}>Your Digital Partner</IntroductionTitle>
                <IntroductionBody className={Styles.standardBody}>We design compelling digital products. Focused on Design, EdTech and Digital Learning, we build solutions that help you excel.</IntroductionBody>
                <IntroductionNavLink className={Styles.standardNavLink} to="/" key="1">Our Approach</IntroductionNavLink>
            </IntroductionWrapper>
        </IntroductionSection>
    </>
    )
}

export default Introduction
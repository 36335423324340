import React from 'react';
import styled from 'styled-components/macro';

const HeroTitleSection = styled.section`
    height: 69vh;
    max-height: 1100px;
    position: relative;
    overflow: hidden;
    width: 100%;
`;

const HeroTitleWrapper = styled.div`
    align-items: center;
    color: #ffffff;
    display: flex;
    font-family: 'TerminaW05-Bold';
    font-size: 3rem;
    height: 100%;
    justify-content: center;
    margin: 0 auto;
    max-width: 1024px;
    position: relative;
    overflow: hidden;
    width: 100%;

    @media screen and (max-width: 1024px) {
        font-size: 2.5rem;
    }

    @media screen and (max-width: 526px) {
        font-size: 1.5rem;
    }
`;

const HeroTitle = ({title}) => {
  return (
    <HeroTitleSection>
        <HeroTitleWrapper>
            {title}
        </HeroTitleWrapper>
    </HeroTitleSection>
  )
}

export default HeroTitle
import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components'
import '../css/contact-us.css';
import Footer from '../layouts/Footer';
import {Link } from "react-router-dom";
import ScrollToTopOnMount from '../ScrollToTop';
import AvatarImg from "../../images/contact-us-robot.png";
import ImageOne from "../../images/contact-us.png";
import Office1IconImg from '../../images/india_icon.png'
import Office2IconImg from '../../images/canada_icon.png'
import Office3IconImg from '../../images/uk_icon.png'

const StyledImage = styled.img``
const BackgroundImg = styled.img`
    display: block;
    max-width: 950px;
    width: 100%;
`
const AvatarImage = styled.img`
    bottom: -260px;
    display: block;
    left: 50%;
    max-width: 304px;
    position: absolute;
    transform: translateX(-40%);

    @media screen and (max-width: 526px) {
        bottom: -210px;
        max-width: 175px;
    }
`
const List = css`
    display: inline-block;
    left: 50%;
    max-width: 1000px;
    position: relative;
    transform: translateX(-40%);

    @media screen and (max-width: 768px) {
        transform: translateX(-50%);
        width: 100%;
    }
`
const OfficesList = styled.div`
    ${List};
    margin-top: 50px;
`
const Office = css`
    float: left;
    position: relative;
    width: 22%;

    * {
        padding: 9px 0 0;

        &.morePadding {
            padding: 30px 0 0;
        }
        &.lessPadding {
            padding: 5px 0 0;
        }
        &.noPadding {
            padding: 0;
        }
        &:not(img) {
            float: left;
            position: relative;
            width: 100%;
        }
    }

    @media screen and (max-width: 768px) {
        margin-left: 35%;
        position: relative;
        text-align: left;
        width: 30%;

        &:not(:first-child) {
            margin-top: 50px;
        }
    }

    @media screen and (max-width: 520px) {
        margin-left: 25%;
        position: relative;
        text-align: left;
        width: 50%;
    }
`
const Office1 = styled.div`
    ${Office};
`
const Office2 = styled.div`
    ${Office};
`
const Office3 = styled.div`
    ${Office};
`
const Office4 = styled.div`
    ${Office};
`
// const Heading1 = styled.div`
//     font-size: 22px;
//     font-weight: 500;
// `
const Heading2 = styled.div`
    font-family: 'TerminaW05-Bold';
    font-size: 16px;
    font-weight: 700;
`
// const Heading3 = styled.div`
//     font-size: 16px;
//     font-weight: 700;
// `
const Heading4 = styled.div`
    font-size: 40px;
    font-weight: 100;
`
// const Heading5 = styled.div`
//     font-size: 45px;
//     font-weight: 100;
// `
const PlainText = styled.div`
    font-size: 16px;
`
const ContactUs = ({setPageID}) => {
    const [currentIndiaTime, setIndiaTime] = useState('');
    const [currentCanadaTime, setCanadaTime] = useState('');
    const [currentUKTime, setUKTime] = useState('');

    const updateTime = () => {
        let date = new Date();
        let hours = date.getHours();
        let min = date.getMinutes();
        let sec = date.getSeconds();
        let time = (hours < 10 ? '0'+hours : hours) + ':' + (min < 10 ? '0'+min : min) + ':' + (sec < 10 ? '0'+sec : sec);

        setIndiaTime(time);

        let utc = date.getTime() + (date.getTimezoneOffset() * 60000);
        let canadaDate = new Date(utc + (3600000*-4));

        hours = canadaDate.getHours();
        min = canadaDate.getMinutes();
        sec = canadaDate.getSeconds();
        time = (hours < 10 ? '0'+hours : hours) + ':' + (min < 10 ? '0'+min : min) + ':' + (sec < 10 ? '0'+sec : sec);

        setCanadaTime(time);

        let Gtc = date.getTime() + (date.getTimezoneOffset() * 60000);
        let UKDate = new Date(Gtc + (3600000));

        hours = UKDate.getHours();
        min = UKDate.getMinutes();
        sec = UKDate.getSeconds();
        time = (hours < 10 ? '0'+hours : hours) + ':' + (min < 10 ? '0'+min : min) + ':' + (sec < 10 ? '0'+sec : sec);

        setUKTime(time);
    }

    setInterval(updateTime, 1000);

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    useEffect(() => {
        setPageID(4);

        return () => {
            setPageID(-1);
        };
    }, []);
    return (
        <>
            <ScrollToTopOnMount />
            <div className='contact-us-top-banner'>
                
            </div>
            <div className='blank-block'>
                <div className='contact-us-banner-text'>No. We haven't yet opened our office on Mars. But have a few on the Earth. Get in touch!</div>
                <AvatarImage src={AvatarImg} />
            </div>
            <div className='app-contact-us'>
                <div className='app-contact-us-inner '>
                    <div className='app-contact-us-body'>Where you'll find us</div>
                </div>
                <div className='app-contact-us-top-inner'>
                    <BackgroundImg src={ImageOne} />
                    <OfficesList>
                        <Office1>
                            <StyledImage src={Office1IconImg} />
                            <Heading2>Jaipur</Heading2>
                            <PlainText className='lessPadding'>India</PlainText>
                            <PlainText className='morePadding'>Regus, Sixth Floor</PlainText>
                            <PlainText className='lessPadding'>Jaipur Centre, Jaipur</PlainText>
                            <PlainText className='lessPadding'>RJ, India 302018</PlainText>
                            <PlainText className='morePadding'>hello@arize.co.in</PlainText>
                            <PlainText className='lessPadding'>+91 8209 814 322</PlainText>
                            <Heading4 className='morePadding'>{currentIndiaTime}</Heading4>
                        </Office1>
                        <Office2>
                            <StyledImage src={Office1IconImg} />
                            <Heading2>Pune</Heading2>
                            <PlainText className='lessPadding'>India</PlainText>
                            <PlainText className='morePadding'>403, Platinum Square,</PlainText>
                            <PlainText className='lessPadding'>Viman Nagar, Pune</PlainText>
                            <PlainText className='lessPadding'>India 411014</PlainText>
                            <PlainText className='morePadding'>hello@arize.co.in</PlainText>
                            <PlainText className='lessPadding'>+91 8209 814 322</PlainText>
                            <Heading4 className='morePadding'>{currentIndiaTime}</Heading4>
                        </Office2>
                        <Office3>
                            <StyledImage src={Office2IconImg} />
                            <Heading2>Toronto</Heading2>
                            <PlainText className='lessPadding'>Canada</PlainText>
                            <PlainText className='morePadding'>3080 Yonge Street</PlainText>
                            <PlainText className='lessPadding'>Suite 6060, Toronto</PlainText>
                            <PlainText className='lessPadding'>ON, Canada M4N 3N1</PlainText>
                            <PlainText className='morePadding'>hello@arize.co.in</PlainText>
                            <PlainText className='lessPadding'>+1 718 509 6711</PlainText>
                            <Heading4 className='morePadding'>{currentCanadaTime}</Heading4>
                        </Office3>
                        <Office4>
                            <StyledImage src={Office3IconImg} />
                            <Heading2>London</Heading2>
                            <PlainText className='lessPadding'>England</PlainText>
                            <PlainText className='morePadding'>71-75 Shelton Street</PlainText>
                            <PlainText className='lessPadding'>London, England</PlainText>
                            <PlainText className='lessPadding'>WC2H 9JQ</PlainText>
                            <PlainText className='morePadding'>hello@arize.co.in</PlainText>
                            <PlainText className='lessPadding'>+44 (0) 203 861 7905</PlainText>
                            <Heading4 className='morePadding'>{currentUKTime}</Heading4>
                        </Office4>
                    </OfficesList>
                </div>
                <div className='app-contact-us-middle-inner'>
                    <div className='contact-us-getTouch-outer'><Link to="#" onClick={() => openInNewTab('https://jitendra623226.typeform.com/to/p4ebZOJI?typeform-source=arize.co.in')}>Contact Us</Link></div>
                </div>
            </div>
            <Footer pageID={5} />
        </>
    )
}

export default ContactUs

import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Button = styled(Link)`
    background: ${({primary}) => (primary ? "#F97274" : "none")};
    border-radius: ${({primary}) => (primary ? "3px" : "none")};
    white-space: nowrap;
    border: none;
    height: 100%;
    cursor: pointer;
    text-decoration: none;
    transition: 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 24px;
    color: #fff;
    font-size: ${({dropdown}) => (dropdown ? "16px" : "0.93rem")};
    font-weight: bold;
    // max-height: 35px;
    width: 137px;

    &:hover {
        transform: ${({dropdown}) => (dropdown ? "none" : "translateY(-2px)")};
    }
`;
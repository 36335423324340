import React, { useEffect } from 'react';
import styled from 'styled-components'
import '../css/approach.css';
import Footer from '../layouts/Footer';
import {Link } from "react-router-dom";
import ScrollToTopOnMount from '../ScrollToTop';
import AvatarImg from '../../images/approach_robo.png'

const AvatarImage = styled.img`
    position: absolute;
    top: -20px;
    right: -60px;
`
const Approach = ({setPageID}) => {
    useEffect(() => {
        setPageID(0);

        return () => {
            setPageID(-1);
        };
    }, []);
    return (
        <>
            <ScrollToTopOnMount />
            <div className='app-top-banner'>
                
            </div>
            <div className='blank-block'>
                <div className='approach-banner-text'>We work with you - not for you.</div>
            </div>
            <div className='app-approach'>
                <div className='app-approach-inner '>
                    <div className='app-approach-title'>Approach.</div>
                    <div className='app-approach-body'>We work with you—not for you.<br/><br/>We co-design with our clients to create solutions that people love to use.<br/><br/>Design thinking and creativity is part of every step at Arize. Arize thrives on its love for aesthetics, craftsmanship and customer experience.</div>
                    <div className='app-approach-body2'>Our approach to Design is a five step process, we call it the 5D Process. The 5D enables us harness the power of User Research, Ideation, Strategy &amp; Design to build solutions more humane, and lovesome.</div>
                
                <div className='app-approach-top-inner'>
                    <AvatarImage src={AvatarImg} />
                    <div className='approach-box-container'>
                        <div className='approach-box1 top-round-corner'>
                            <div className='approach-box1-num'>1</div>
                            <div className='approach-box1-title'>Discover</div>
                            <div className='approach-box1-body'>Explore and discover the problem</div>
                        </div>
                        <div className='approach-box2'>
                            <div className='approach-box2-num'>2</div>
                            <div className='approach-box2-title'>Define</div>
                            <div className='approach-box2-body'>Define the problem and scope</div>
                        </div>
                        <div className='approach-box1'>
                            <div className='approach-box1-num'>3</div>
                            <div className='approach-box1-title'>Design</div>
                            <div className='approach-box1-body'>Ideate and design solution</div>
                        </div>
                        <div className='approach-box2'>
                            <div className='approach-box2-num'>4</div>
                            <div className='approach-box2-title'>Develop</div>
                            <div className='approach-box2-body'>Develop the prototype</div>
                        </div>
                        <div className='approach-box1 bottom-round-corner'>
                            <div className='approach-box1-num'>5</div>
                            <div className='approach-box1-title'>Deliver</div>
                            <div className='approach-box1-body'>Deliver the prototype</div>
                        </div>
                    </div>
                </div>
                <div className='app-approach-middle-inner'>
                    <div className='app-approach-middle-text'>Let us together find solution to your business problem.</div>
                    <Link to="#" onClick={(e) => {
                        window.location.href = 'mailto:hello@arize.co.in';
                        e.preventDefault();
                    }}>Get in Touch</Link>
                </div>
                </div>
                </div>
                <div className='app-approach-bottom'>
                    <div className='app-approach-bottom-inner'>
                    <div className='app-approach-bottom-inner-top'>
                        <div className='app-approach-bottom-text'><b>Services</b><br/>Explore our services.</div>
                        <div className='app-approach-bottom-box'>
                            <div className='app-approach-bottom-box1'>
                                <div className='app-approach-bottom-box1-trow'>Digital Learning</div>
                                <div className='app-approach-bottom-box1-blef'><Link to="/digital-learning">Explore</Link></div>
                                <div className='app-approach-bottom-box1-brig'></div>
                            </div>

                            <div className='app-approach-bottom-box1'>
                                <div className='app-approach-bottom-box1-trow'>Design &amp; Experience</div>
                                <div className='app-approach-bottom-box1-blef'><Link to="/design-experience">Explore</Link></div>
                                <div className='app-approach-bottom-box2-brig'></div>
                            </div>

                            <div className='app-approach-bottom-box1'>
                                <div className='app-approach-bottom-box1-trow'>Product Development</div>
                                <div className='app-approach-bottom-box1-blef'><Link to="/product-development">Explore</Link></div>
                                <div className='app-approach-bottom-box3-brig'></div>
                            </div>
                        </div>
                    </div>
                        <div className='app-call-us'>Have a <b>question?</b><br/><a href="mailto:jay@arize.co.in"><b>Write to us</b></a> or <b>Call</b> us!</div>
                        
                    </div>
                    
                </div>
            <Footer pageID={0} />
        </>
    )
}

export default Approach

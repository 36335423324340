import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Roboto', sans-sarif;
        transition: 0.3s;
    }

    html, body {
        color: #3d3d3d;
        font-size: 16px;
        overflow-x: hidden;

        @media screen and (max-width: 1024px) {
            font-size: 14px;
        }

        @media screen and (max-width: 526px) {
            font-size: 12px;
        }
    }

    @font-face {font-family: "TerminaW05-Bold"; src: url("//db.onlinewebfonts.com/t/e9591193aa91ce28ad8c8f91c03f6c7b.eot"); src: url("//db.onlinewebfonts.com/t/e9591193aa91ce28ad8c8f91c03f6c7b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/e9591193aa91ce28ad8c8f91c03f6c7b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/e9591193aa91ce28ad8c8f91c03f6c7b.woff") format("woff"), url("//db.onlinewebfonts.com/t/e9591193aa91ce28ad8c8f91c03f6c7b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/e9591193aa91ce28ad8c8f91c03f6c7b.svg#TerminaW05-Bold") format("svg"); }
`;

export default GlobalStyle;
import PageOneHeroVidOne from "../videos/PageOneHeroVidOne.mp4";
import SliderOneImage from "../images/whyus_slider_1.jpg";
import SliderTwoImage from "../images/whyus_slider_2.jpg";
import SliderThreeImage from "../images/whyus_slider_3.jpg";
import SliderFourImage from "../images/whyus_slider_4.png";

export const HeroSliderData = {
    'home': {
        title: "your digital partner.",
        path: "",
        asset: PageOneHeroVidOne
    }
};

export const WhyUsSliderData = [
    {
        title: "Digital Learning & EdTech",
        body: "All your needs for Digital Learning, Content Development and Learning Platforms end at Arize.",
        asset: SliderOneImage
    },
    {
        title: "Design & Branding",
        body: "Transform your business with the power of design.",
        asset: SliderTwoImage
    },
    {
        title: "Digital Product Development",
        body: "Your one-stop partner for digital products for your business.",
        asset: SliderThreeImage
    },
    {
        title: "Experience Design",
        body: "We design user-friendly and apropos digital experiences.",
        asset: SliderFourImage
    }
];
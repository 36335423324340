import React, { useEffect } from 'react';
import styled from 'styled-components'
import Styles from '../css/styles.module.css'
import '../css/product-development.css';
import Footer from '../layouts/Footer';
import {Link } from "react-router-dom";
import ScrollToTopOnMount from '../ScrollToTop';
import ImageOne from "../../images/expertise1.png";
import ImageTwo from "../../images/expertise3.png";
import ImageThree from "../../images/ecommerc-icon.png";

const ProductItemsContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    max-width: 990px;

    @media screen and (max-width: 1199px) {
        max-width: inherit;
    }
    @media screen and (max-width: 991px) {
        display: block;
    }
`
const ProductItem = styled.div`
    margin-right: 15px;
    // max-width: 320px;
    padding: 0 15px 15px;
    // width: calc(20% + 60px);

    &:last-child {
        margin-right: 0;
    }

    @media screen and (max-width: 1199px) {
        max-width: inherit;
        width: calc(100% - 30px / 3);
    }
    @media screen and (max-width: 991px) {
        margin: 0 auto !important;
        max-width: 320px;
    }
`
const Icon = styled.img`

`
const Title = styled.div`
    font-size: 1.4375rem;
    margin-bottom: 25px;
`
const Body = styled.div`
    font-size: 18px;
    margin-bottom: 25px;

    @media screen and (max-width: 1022px) {
        font-size: 16px;
    }
    @media screen and (max-width: 798px) {
        font-size: 14px;
    }
`
// const NavLink = styled(Link)`
//     font-size: 18px;
//     // margin: 0 auto;

//     @media screen and (max-width: 1022px) {
//         font-size: 16px;
//     }
//     @media screen and (max-width: 798px) {
//         font-size: 14px;
//     }
// `
const ProductDevelopment = ({setPageID}) => {
    useEffect(() => {
        setPageID(2);

        return () => {
            setPageID(-1);
        };
    }, []);
    return (
        <>
            <ScrollToTopOnMount />
            <div className='product-top-banner'>
                
            </div>
            <div className='blank-block'>
                {/* <div className='pd-banner-text'>Your learning partner.</div> */}
            </div>
            <div className='app-product'>
                <div className='app-product-inner '>
                    <div className='app-product-title'>Product <br/>Development</div>
                    <div className='app-product-body'>EdTech, Mobile apps, E-commerce, Storefront sites, and customised applications. Arize Digital can support you with all your needs.</div>
                </div>
                <div className='app-product-top-inner'>
                    <ProductItemsContainer>
                        <ProductItem>
                            <Icon src={ImageOne} />
                            <Title className={'title '+Styles.standardTitle}>EdTech</Title>
                            <Body className={'body '+Styles.standardBody}>We design compelling digital products. Focused on product development, design, user-experience and digital learning with our full service approach.</Body>
                        </ProductItem>
                        <ProductItem>
                            <Icon src={ImageTwo} />
                            <Title className={'title '+Styles.standardTitle}>Custom Applications</Title>
                            <Body className={'body '+Styles.standardBody}>We design compelling digital products. Focused on product development, design, user-experience and digital learning with our full service approach.</Body>
                        </ProductItem>
                        <ProductItem>
                            <Icon src={ImageThree} />
                            <Title className={'title '+Styles.standardTitle}>E-Commerce</Title>
                            <Body className={'body '+Styles.standardBody}>We design compelling digital products. Focused on product development, design, user-experience and digital learning with our full service approach.</Body>
                        </ProductItem>
                    </ProductItemsContainer>
                </div>
                <div className='app-product-middle-inner'>
                    <div className='app-product-middle-text'></div>
                    <div className='getTouch-outer'><Link to="#" onClick={(e) => {
                        window.location.href = 'mailto:hello@arize.co.in';
                        e.preventDefault();
                    }}>Let’s talk about your learning project?</Link></div>
                </div>
            </div>
                <div className='app-approach-bottom'>
                    <div className='app-approach-bottom-inner'>
                    <div className='app-approach-bottom-inner-top'>
                        <div className='app-approach-bottom-text'><b>Services</b><br/>Explore our services.</div>
                        <div className='app-approach-bottom-box'>
                        <div className='app-approach-bottom-box1'>
                                <div className='app-approach-bottom-box1-trow'>Approach</div>
                                <div className='app-approach-bottom-box1-blef'><Link to="#">Explore</Link></div>
                                <div className='app-approach-bottom-box0-brig'></div>
                            </div>

                            <div className='app-approach-bottom-box1'>
                                <div className='app-approach-bottom-box1-trow'>Digital Learning</div>
                                <div className='app-approach-bottom-box1-blef'><Link to="/digital-learning">Explore</Link></div>
                                <div className='app-approach-bottom-box2-brig'></div>
                            </div>

                            <div className='app-approach-bottom-box1'>
                                <div className='app-approach-bottom-box1-trow'>Design &amp; Experience</div>
                                <div className='app-approach-bottom-box1-blef'><Link to="/design-experience">Explore</Link></div>
                                <div className='app-approach-bottom-box2-brig'></div>
                            </div>
                        </div>
                    </div>
                        <div className='app-call-us'>Have a <b>question?</b><br/><a href="mailto:jay@arize.co.in"><b>Write to us</b></a> or <b>Call</b> us!</div>  
                    </div>
                    
                </div>
            <Footer pageID={3} />
        </>
    )
}

export default ProductDevelopment

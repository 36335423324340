import React, { useEffect } from 'react';
import { HeroSliderData, WhyUsSliderData } from '../../data/SliderData';
import Hero from '../layouts/Hero';
import HeroTitle from '../layouts/HeroTitle';
import styled from 'styled-components/macro';
import Introduction from '../layouts/home/Introduction';
import WhyUs from '../layouts/home/WhyUs';
import Experties from '../layouts/home/Experties';
import Technologies from '../layouts/home/Technologies';
import TalkWithUs from '../layouts/home/TalkWithUs';
import WriteToUs from '../layouts/home/WriteToUs';
import Footer from '../layouts/Footer';
import ScrollToTopOnMount from '../ScrollToTop';

const SectionWrapper = styled.section`
  float: left;
  padding: 0 30px;
  position: relative;
  width: 100%
`;
const TransitionedBackground = styled.div`
    background: rgba(0, 0, 0, 0) -webkit-linear-gradient(left, rgb(160, 125, 195) 0%, rgb(160, 115, 188) 39%, rgb(96, 197, 255) 100%) repeat scroll 0% 0%;    
    height: calc(100% - 250px);
    left: 0;
    position: absolute;
    top: 250px;
    width: 100%;
`

const divider = {
  backgroundColor: "#000000",
  float: "left",
  height: "5px",
  position: "relative",
  width: "100%"
}

const Home = ({setPageID}) => {
  useEffect(() => {
    setPageID(-1);

    return () => {
        setPageID(-1);
    };
  }, []);
  return (
    <>
      <ScrollToTopOnMount />
      <Hero slides={HeroSliderData} page={"home"} />
      <SectionWrapper>
        <HeroTitle title={HeroSliderData["home"].title} />
        <Introduction/>
        <WhyUs slides={WhyUsSliderData}/>
        <Experties />
      </SectionWrapper>
      <SectionWrapper style={{ float: "left" }} >
        <TransitionedBackground />
        <Technologies />
        <TalkWithUs />
        <div style={divider} />
        <WriteToUs />
      </SectionWrapper>
      <Footer />
    </>
  )
}

export default Home
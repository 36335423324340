import React from 'react';
import styled from 'styled-components/macro';
// import HeroTitle from './HeroTitle';

const HeroSection = styled.section`
    height: 100vh;
    left: 0;
    max-height: 1100px;
    position: absolute;
    top: 0;
    overflow: hidden;
    width: 100vw;
`;

const HeroWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    overflow: hidden;
`;

const HeroVideo = styled.video`
    height: 100%;
    object-fit: cover;
    width: 100%;
`;

const Hero = ({ slides, page }) => {
    return (
        <HeroSection>
            <HeroWrapper>
                <HeroVideo playsinline={true} autoPlay={true} loop={true} muted src={slides[page].asset} />
                {/* <HeroTitle title={slides[page].title} /> */}
            </HeroWrapper>
        </HeroSection>
    )
}

export default Hero

import React, { useEffect } from 'react';
import styled from 'styled-components'
import '../css/design-experience.css';
import Footer from '../layouts/Footer';
import {Link } from "react-router-dom";
import ScrollToTopOnMount from '../ScrollToTop';
import IntersectionVisible from 'react-intersection-visible';
import Icon1 from '../../images/design_icon01.png';
import Icon2 from '../../images/design_icon02.png';
import Icon3 from '../../images/design_icon03.png';
import Icon4 from '../../images/design_icon04.png';
import Icon5 from '../../images/design_icon05.png';
import Icon6 from '../../images/design_icon06.png';
import Icon7 from '../../images/design_icon07.png';
import Icon8 from '../../images/design_icon08.png';
import Icon9 from '../../images/design_icon09.png';

const Icon = styled.img`
`

const DesignExperience = ({setPageID}) => {
    const onShow = ( entries ) => {
        // {
            if(!entries[0].target.classList.contains('animate-in'))
                entries[0].target.classList.add('animate-in');
        // }
    }
    useEffect(() => {
        setPageID(1);

        return () => {
            setPageID(-1);
        };
    }, []);
    return (
        <>
            <ScrollToTopOnMount />
            <div className='des-top-banner'>
                
            </div>
            <div className='blank-block'>
                {/* <div className='de-banner-text'>Your learning partner.</div> */}
            </div>
            <div className='app-design'>
                <div className='app-design-inner '>
                    <div className='app-design-title'>Design &amp; <br/>Experience</div>
                    <div className='app-design-body'>We make your product shine with great aesthetics and awesome experiences. Arize is a team of bright creatives who help you get the best out of your product. We are a full-stack design service company.</div>
                    <div className='app-design-body2'>Our range of design services include user research, design audit, heuristics, user interface and product designs, illustrations, graphics, animations, branding and prototyping.</div>
                </div>
                <div className='app-design-top-inner'>
                    <IntersectionVisible options={{threshold: 1}} className='design-box-container design-box1-container' onShow={ e => onShow( e ) }>
                        <div className='design-box design-box1'>
                            <div className='design-box1-title'>UX Research</div>
                            <div className='design-box1-img'>
                                <Icon src={Icon1} />
                            </div>
                        </div>
                    </IntersectionVisible>
                    <IntersectionVisible options={{threshold: 1}} className='design-box-container design-box2-container' onShow={ e => onShow( e ) }>
                        <div className='design-box design-box2'>
                            <div className='design-box2-title'>Design Strategy</div>
                            <div className='design-box1-img'>
                                <Icon src={Icon2} />
                            </div>
                        </div>
                    </IntersectionVisible>
                    <IntersectionVisible options={{threshold: 1}} className='design-box-container design-box2-container' onShow={ e => onShow( e ) }>
                        <div className='design-box design-box2'>
                            <div className='design-box1-title'>Interaction Design</div>
                            <div className='design-box1-img'>
                                <Icon src={Icon3} />
                            </div>
                        </div>
                    </IntersectionVisible>
                    <IntersectionVisible options={{threshold: 1}} className='design-box-container design-box2-container' onShow={ e => onShow( e ) }>
                        <div className='design-box design-box1'>
                            <div className='design-box2-title'>Interface Design</div>
                            <div className='design-box1-img'>
                                <Icon src={Icon4} />
                            </div>
                        </div>
                    </IntersectionVisible>
                    <IntersectionVisible options={{threshold: 1}} className='design-box-container design-box2-container' onShow={ e => onShow( e ) }>
                        <div className='design-box design-box1'>
                            <div className='design-box1-title'>Branding</div>
                            <div className='design-box1-img'>
                                <Icon src={Icon5} />
                            </div>
                        </div>
                    </IntersectionVisible>
                    <IntersectionVisible options={{threshold: 1}} className='design-box-container design-box2-container' onShow={ e => onShow( e ) }>
                        <div className='design-box design-box2'>
                            <div className='design-box2-title'>Prototyping</div>
                            <div className='design-box1-img'>
                                <Icon src={Icon6} />
                            </div>
                        </div>
                    </IntersectionVisible>
                    <IntersectionVisible options={{threshold: 1}} className='design-box-container design-box2-container' onShow={ e => onShow( e ) }>
                        <div className='design-box design-box2'>
                            <div className='design-box1-title'>Graphic Design &amp; Illustrations</div>
                            <div className='design-box1-img'>
                                <Icon src={Icon7} />
                            </div>
                        </div>
                    </IntersectionVisible>
                    <IntersectionVisible options={{threshold: 1}} className='design-box-container design-box2-container' onShow={ e => onShow( e ) }>
                        <div className='design-box design-box1'>
                            <div className='design-box2-title'>Animation &amp; Motion Design</div>
                            <div className='design-box1-img'>
                                <Icon src={Icon8} />
                            </div>
                        </div>
                    </IntersectionVisible>
                    <IntersectionVisible options={{threshold: 1}} className='design-box-container design-box2-container' onShow={ e => onShow( e ) }>
                        <div className='design-box design-box1'>
                            <div className='design-box1-title'>Data Visualisation</div>
                            <div className='design-box1-img'>
                                <Icon src={Icon9} />
                            </div>
                        </div>
                    </IntersectionVisible>
                </div>
                <div className='app-design-middle-inner'>
                    <div className='app-design-middle-text'></div>
                    <div className='getTouch-outer'><Link to="#" onClick={(e) => {
                        window.location.href = 'mailto:hello@arize.co.in';
                        e.preventDefault();
                    }}>Let’s talk about your learning project?</Link></div>
                </div>
            </div>
                <div className='app-approach-bottom'>
                    <div className='app-approach-bottom-inner'>
                    <div className='app-approach-bottom-inner-top'>
                        <div className='app-approach-bottom-text'><b>Services</b><br/>Explore our services.</div>
                        <div className='app-approach-bottom-box'>
                            <div className='app-approach-bottom-box1'>
                                <div className='app-approach-bottom-box1-trow'>Approach</div>
                                <div className='app-approach-bottom-box1-blef'><Link to="#">Explore</Link></div>
                                <div className='app-approach-bottom-box0-brig'></div>
                            </div>

                            <div className='app-approach-bottom-box1'>
                                <div className='app-approach-bottom-box1-trow'>Digital Learning</div>
                                <div className='app-approach-bottom-box1-blef'><Link to="/digital-learning">Explore</Link></div>
                                <div className='app-approach-bottom-box2-brig'></div>
                            </div>

                            <div className='app-approach-bottom-box1'>
                                <div className='app-approach-bottom-box1-trow'>Product Development</div>
                                <div className='app-approach-bottom-box1-blef'><Link to="/product-development">Explore</Link></div>
                                <div className='app-approach-bottom-box3-brig'></div>
                            </div>
                        </div>
                    </div>
                        <div className='app-call-us'>Have a <b>question?</b><br/><a href="mailto:jay@arize.co.in"><b>Write to us</b></a> or <b>Call</b> us!</div>  
                    </div>
                    
                </div>
            <Footer pageID={2} />
        </>
    )
}

export default DesignExperience

import React from 'react'
import styled from 'styled-components'
import { menuData } from '../data/MenuData'
import { Link } from 'react-router-dom'
import { Button } from './layouts/Button'

const DropdownContainer = styled.div`
    align-items: center;
    background: rgba(29, 29, 32, 0.8);
    display: none;
    // height: 100%;
    max-width: 350px;
    opacity: ${({isOpen}) => (isOpen ? '1' : '0')};
    padding: 2rem;
    position: fixed;
    right: 30px;
    top: 97px;
    transform: ${({isOpen}) => (isOpen ? 'scaleY(1)' : 'scaleY(0)')};
    transform-origin: top;
    // top: ${({isOpen}) => (isOpen ? '80px' : '-100%')};
    transition: 0.3s ease-in-out;
    width: 100%;
    z-index: 999;

    @media screen and (max-width: 1024px) {
        display: grid;
    }

    @media screen and (max-width: 768px) {
        right: 0;
        top: 77px;
    }
`

const DropdownWrapper = styled.div``

const DropdownMenu = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 50px);
    text-align: left;
    // margin-bottom: 4rem;
`

const DropdownLink = styled(Link)`
    // align-items: center;
    color: #ffffff;
    custor: pointer;
    // display: flex;
    font-size: 16px;
    // justify-content: center;
    list-style: none;
    text-decoration: none;
    transition: 0.2s ease-in-out;

    &.selected {
        color: #afafaf;
        cursor: auto;
        pointer-events: none;
    }
`

const BtnWrap = styled.div`
    background-color: #F97274;
    border-radius: 3px;
    display: flex;
    justify-content: center;
`

const Dropdown = ({isOpen, toggle, pageID}) => {
  return (
    <DropdownContainer isOpen={isOpen} onClick={toggle}>
        <DropdownWrapper>
            <DropdownMenu>
                {menuData.map((item, index) => (
                    <DropdownLink to={item.link} key={index} className={(pageID === index ? ' selected' : '')}>
                        {item.title}
                    </DropdownLink>
                ))}
            </DropdownMenu>
            <BtnWrap>
                <Button dropdown='true' to='/startProject'>Start a project</Button>
            </BtnWrap>
        </DropdownWrapper>
    </DropdownContainer>
  )
}

export default Dropdown
import React from 'react'
import styled from 'styled-components/macro';

const WriteToUsSection = styled.section`
    color: #ffffff;
    float: left;
    padding: 0 30px;
    position: relative;
    text-align: center;
    width: 100%;

    @media screen and (max-width: 768px) {
        padding: 0;
    }
`;

const WriteToUsWrapper = styled.div`
    font-size: 30px;
    font-weight: 100;
    margin: 140px auto;
    max-width: 550px;
    overflow: hidden;
    position: relative;
    width: 100%;

    @media screen and (max-width: 1024px) {
        font-size: 25px;
    }
    @media screen and (max-width: 768px) {
        font-size: 21px;
    }

    a {
        color: #ffffff;
        text-decoration: none;

        &:hover {
            color: #000000;
        }
    }
`;

const MediumFont = {
    fontWeight: 500
}

const WriteToUs = () => {
    return (
        <>
            <WriteToUsSection >
                <WriteToUsWrapper >
                    We are always looking for like-minded people to <span style={MediumFont}>join our team</span>. If you are interested, <span style={MediumFont}><a href="mailto:jay@arize.co.in">write to us</a></span>.
                </WriteToUsWrapper>
            </WriteToUsSection>
        </>
    )
}

export default WriteToUs
import React from 'react'
import styled from 'styled-components/macro';
import BackgroundImg from "../../../images/arize_robo_home.png";
import { Link } from 'react-router-dom';

const TalkWithUsSection = styled.section`
    color: #ffffff;
    float: left;
    padding: 0;
    position: relative;
    text-align: center;
    width: 100%;
`;

const TalkWithUsWrapper = styled.div`
    margin: 140px auto;
    max-width: 980px;
    overflow: hidden;
    position: relative;
    width: 100%;
`;

const BackgroundImage = styled.img`
    max-width: 273px;
    padding-left: 20px;

    @media screen and (max-width: 300px) {
        width: 100%;
    }
`

const LetsTalkLink = styled(Link)`
    background: #f97274;
    color: #ffffff;
    font-family: 'TerminaW05-Bold';
    font-size: 18px;
    left: 50%;
    max-width: 415px;
    padding: 15px 35px;
    position: absolute;
    text-decoration: none;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    &:hover {
        opacity: 0.76
    }
`

const TalkWithUs = () => {
    return (
        <>
            <TalkWithUsSection >
                <TalkWithUsWrapper >
                    <BackgroundImage src={BackgroundImg} />
                    <LetsTalkLink to='#' key='1' onClick={(e) => {
                        window.location.href = 'mailto:hello@arize.co.in';
                        e.preventDefault();
                    }}>Let's talk about your project?</LetsTalkLink>
                </TalkWithUsWrapper>
            </TalkWithUsSection>
        </>
    )
}

export default TalkWithUs
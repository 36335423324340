import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import Styles from '../css/styles.module.css'
import { Link } from 'react-router-dom';
import { menuData } from '../../data/MenuData';
import { Button } from './Button';
import companyLogo from '../../images/Logo-Arize.svg';
import { FaBars } from 'react-icons/fa';

const Nav = styled.nav`
    background: rgba(29, 29, 32, 0.8);
    display: flex;
    height: 72px;
    justify-content: space-between;
    margin: 20px 30px;
    margin-bottom: 0;
    width: calc(100% - 60px);
    padding: 17px 30px;
    margin-top: ${props => props.positioning.marginTop};
    position: ${props => props.positioning.position};
    z-index: 2;
    top: 0;

    @media screen and (max-width: 1049px) {
        position: absolute !important;
    }
    @media screen and (max-width: 768px) {
        margin: 0;
        width: 100%;
    }
`;

const NavWrapper = styled.div`
    height: 92px;
    position: relative;
    z-index: 999;

    @media screen and (max-width: 768px) {
        height: 72px;
    }
`

Nav.defaultProps = {
    positioning: {
        position: "absolute",
        marginTop: "20px"
    }
}

const MenuBars = styled(FaBars)`
    color: white;
    cursor: pointer;
    display: none;
    font-size: 24px;
    height: 2.5rem;
    position: relative;
    top: 50%;
    transform: translateY(-50%);

    @media screen and (max-width: 1023px) {
        display: block;
    }
`;

const NavMenu = styled.div`
    align-items: center;
    display: flex;

    @media screen and (max-width: 1023px) {
        display: none;
    }
`;

const Logo = styled(Link)`
    background-image: url(${companyLogo});
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: center;
    height: 44px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: 97px;
`;

const NavMenuLinks = styled(Link)`
    color: #ffffff;
    margin: 0 0.5rem;

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }

    @media screen and (max-width: 1340px) {
        font-size: 1rem;
    }

    &::after {
        background: #a5a5a5;
        bottom: 0;
        height: 2px;
    }

    &.selected {
        cursor: auto;
        pointer-events: none;

        &::after {
            width: 100%;
            left: 0;
        }
    }
`;

const NavBtn = styled.div`
    align-items: center;
    display: flex;

    @media screen and (max-width: 1023px) {
        display: none;
    }
`;

const Navbar = ({toggle, pageID}) => {
    const [isSticky, setSticky] = useState(false);
    const ref = useRef(null);
    const handleScroll = () => {
        if (ref.current) {
            setSticky(ref.current.getBoundingClientRect().top < 0);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', () => handleScroll);
        };
    }, []);

    return (
        <NavWrapper ref={ref}>
            <Nav positioning={isSticky ? {position: "fixed", marginTop:0} : {position: "absolute", marginTop:"20px"}} >
                <Logo to="/home" />
                <MenuBars onClick={toggle}></MenuBars>
                <NavMenu>
                    {menuData.map((item, index) => (
                        <NavMenuLinks className={Styles.standardNavLink + '' + (pageID === index ? ' selected' : '')} to={item.link} key={index}>
                            {item.title}
                        </NavMenuLinks>
                    ))}
                </NavMenu>
                <NavBtn>
                    <Button to='#' onClick={(e) => {
                        window.location.href = 'mailto:hello@arize.co.in';
                        e.preventDefault();
                    }} primary='true'>Start a project</Button>
                </NavBtn>
            </Nav>
        </NavWrapper>
    )
};

export default Navbar

// import React, { useState, useEffect } from 'react';
import React, { useEffect } from 'react';
import styled from 'styled-components'
import '../css/about-us.css';
import Footer from '../layouts/Footer';
import {Link } from "react-router-dom";
import ScrollToTopOnMount from '../ScrollToTop';
import ImageOne from "../../images/about-us.jpg";
// import Office1IconImg from '../../images/india_icon.png'
// import Office2IconImg from '../../images/canada_icon.png'

// const StyledImage = styled.img``
const BackgroundImg = styled.img`
    display: block;
    max-width: 950px;
    width: 100%;
`
// const List = css`
//     display: inline-block;
//     left: 50%;
//     max-width: 800px;
//     position: relative;
//     transform: translateX(-40%);

//     @media screen and (max-width: 768px) {
//         transform: translateX(-50%);
//         width: 100%;
//     }
// `
// const OfficesList = styled.div`
//     ${List};
//     margin-top: 50px;
// `
// const Office = css`
//     float: left;
//     position: relative;
//     width: 33%;

//     * {
//         padding: 9px 0 0;

//         &.morePadding {
//             padding: 30px 0 0;
//         }
//         &.lessPadding {
//             padding: 5px 0 0;
//         }
//         &.noPadding {
//             padding: 0;
//         }
//         &:not(img) {
//             float: left;
//             position: relative;
//             width: 100%;
//         }
//     }

//     @media screen and (max-width: 768px) {
//         margin-left: 35%;
//         position: relative;
//         text-align: left;
//         width: 30%;

//         &:not(:first-child) {
//             margin-top: 50px;
//         }
//     }

//     @media screen and (max-width: 520px) {
//         margin-left: 25%;
//         position: relative;
//         text-align: left;
//         width: 50%;
//     }
// `
// const Office1 = styled.div`
//     ${Office};
// `
// const Office2 = styled.div`
//     ${Office};
// `
// const Office3 = styled.div`
//     ${Office};
// `
// const Heading1 = styled.div`
//     font-size: 22px;
//     font-weight: 500;
// `
// const Heading2 = styled.div`
//     font-family: 'TerminaW05-Bold';
//     font-size: 16px;
//     font-weight: 700;
// `
// const Heading3 = styled.div`
//     font-size: 16px;
//     font-weight: 700;
// `
// const Heading4 = styled.div`
//     font-size: 40px;
//     font-weight: 100;
// `
// const Heading5 = styled.div`
//     font-size: 45px;
//     font-weight: 100;
// `
// const PlainText = styled.div`
//     font-size: 16px;
// `
const AboutUs = ({setPageID}) => {
    // const [currentIndiaTime, setIndiaTime] = useState('');
    // const [currentCanadaTime, setCanadaTime] = useState('');

    // const updateTime = () => {
    //     let date = new Date();
    //     let hours = date.getHours();
    //     let min = date.getMinutes();
    //     let sec = date.getSeconds();
    //     let time = (hours < 10 ? '0'+hours : hours) + ':' + (min < 10 ? '0'+min : min) + ':' + (sec < 10 ? '0'+sec : sec);

    //     setIndiaTime(time);

    //     let utc = date.getTime() + (date.getTimezoneOffset() * 60000);
    //     let canadaDate = new Date(utc + (3600000*-4));

    //     hours = canadaDate.getHours();
    //     min = canadaDate.getMinutes();
    //     sec = canadaDate.getSeconds();
    //     time = (hours < 10 ? '0'+hours : hours) + ':' + (min < 10 ? '0'+min : min) + ':' + (sec < 10 ? '0'+sec : sec);

    //     setCanadaTime(time);
    // }

    // setInterval(updateTime, 1000);

    useEffect(() => {
        setPageID(3);

        return () => {
            setPageID(-1);
        };
    }, []);
    return (
        <>
            <ScrollToTopOnMount />
            <div className='about-us-top-banner'>
                
            </div>
            <div className='blank-block'>
                <div className='app-banner-text'>Something about us</div>
            </div>
            <div className='app-about-us'>
                {/* <div className='app-about-us-inner '>
                    <div className='app-about-us-body'>Something about us</div>
                </div> */}
                <div className='app-about-us-top-inner'>
                    <BackgroundImg src={ImageOne} />
                    <div className='app-contact-us-description'>
                        Though Arize Digital is not very old, our team is enormously experienced. We proudly look back on 18 years of experience of our team in developing digital learning content and platforms. With a passion for technology, awareness of outstanding quality and always focusing on the needs of our customers, we have implemented many varied projects.<br /><br />We developed further in the direction of the design, and the user experience, and today we focus on the successful development of digital products and services.<br /><br />In a phase in which everyone is out for quick money, we want to set Arize Digital apart and oppose the general consensus. Our focus is to build a strong team that values customer satisfaction before anything else, and to help grow the culture of innovation. This helps us to look at projects from different perspectives and to find the best possible solutions for our customers. This helps us develop products and services that inspire. This is how we accompany our customers into their digital future.
                    </div>
                    <div className='app-about-us-middle-inner'>
                        <div className='app-about-us-middle-text'></div>
                        <div className='about-us-getTouch-outer'><Link to="#" onClick={(e) => {
                            window.location.href = 'mailto:hello@arize.co.in';
                            e.preventDefault();
                        }}>Let’s talk about your learning project?</Link></div>
                    </div>
                </div>
            </div>
            <Footer pageID={4} />
        </>
    )
}

export default AboutUs

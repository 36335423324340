import React from 'react'
import styled from 'styled-components/macro';
import ImageOne from "../../../images/technology_1.png";
import ImageTwo from "../../../images/technology_2.png";
import ImageThree from "../../../images/technology_3.png";
import ImageFour from "../../../images/technology_4.png";
import ImageFive from "../../../images/technology_5.png";
import ImageSix from "../../../images/technology_6.png";

const TechnologiesSection = styled.section`
    background:  #efedfa;
    color: #ffffff;
    float: left;
    padding: 0 30px;
    position: relative;
    text-align: center;
    width: 100%;
`;

const TechnologiesWrapper = styled.div`
    background: #ffffff;
    margin: 0 auto 100px auto;
    max-width: 980px;
    overflow: hidden;
    padding: 35px 50px 100px;
    position: relative;
    width: 100%;
`;

const TechnologiesIcon = styled.img`
    max-height: 104px;
    width: auto;
    margin: 1% 2% 1% 2%;

    @media screen and (max-width: 768px) {
        width: calc(100% - 2%);
    }
`
const Technologies = () => {
    return (
        <>
            <TechnologiesSection >
                <TechnologiesWrapper >
                    <TechnologiesIcon src={ImageOne} />
                    <TechnologiesIcon src={ImageTwo} />
                    <TechnologiesIcon src={ImageThree} />
                    <TechnologiesIcon src={ImageFour} />
                    <TechnologiesIcon src={ImageFive} />
                    <TechnologiesIcon src={ImageSix} />
                </TechnologiesWrapper>
            </TechnologiesSection>
        </>
    )
}

export default Technologies
import React, { useState, useRef, useEffect } from 'react'
import styled, { css } from 'styled-components/macro'
import TrickleIcon from "../../images/trickle.png"
import ClientLogoImg from '../../images/Logo-Arize-Black.svg'
import Office1IconImg from '../../images/india_icon.png'
import Office2IconImg from '../../images/canada_icon.png'
import Office3IconImg from '../../images/uk_icon.png'
import FooterAvatarImg from '../../images/footer_robo.png'
import Styles from '../css/styles.module.css'
import { Link } from 'react-router-dom'

const FooterSection = styled.section`
    background: #f8f8f8;
    color: #262626;
    float: left;
    overflow: visible;
    padding: 0 30px;
    position: relative;
    width: 100%
`
const FooterWrapper = styled.div`
    padding: 65px 30px 75px;

    @media screen and (max-width: 520px) {
        text-align: center;
    }
`;
const Trickle = styled.div`
  background: url(${TrickleIcon}) no-repeat;
  cursor: pointer;
  top: -57px;
  height: 115px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 115px;
`
const ClientLogo = styled.img`
    float: left;
    max-height:65px;
    @media screen and (max-width: 520px) {
        float: none;
    }
`
const StyledImage = styled.img``
const ClientSlogan = styled.div`
    color: #606060;
    float: right;
    font-size: 36px;
    font-weight: 100;

    @media screen and (max-width: 520px) {
        float: none;
        width: 100%;
    }
`
const Block = css`
    float: left;
    position: relative;
    width: 100%;
`
const BlockOne = styled.div`
    ${Block};
`
const BlockTwo = styled.div`
    ${Block};
    margin-top: 30px;
    text-align: center;
`
const BlockThree = styled.div`
    ${Block};
    margin-top: 30px;
    overflow: hidden;
    text-align: center;

    img {
        bottom: -117px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
    }
`
const List = css`
    float: left;
    margin-top: 0;
    position: relative;

    @media screen and (max-width: 768px) {
        width: 100%;
    }
`
const PagesList = styled.div`
    ${List};
    width: 60%;
`
const OfficesList = styled.div`
    ${List};
    width: 40%;

    @media screen and (max-width: 768px) {
        margin-top: 25px;
    }
`
const LinksInARow = styled.div`
    float: left;
    position: relative;
    width: 100%;

    a {
        margin-right: 20px;
        width: auto;

        &:last-child {
            margin-right: 0;
        }
    }
`
const RoutLink = css`
    color: #262626;
    float: left;
    font-size: 16px;
    font-weight: 400;
    padding: 9px 0 0;
    position: relative;
    text-decoration: none;
    width: 100%;
`
const BoldedLink = styled(Link)`
    ${RoutLink};
    font-size: 18px;
    font-weight: 500;

    &.selected {
        color: #9f9f9f;
        cursor: auto;
        pointer-events: none;
    }
    &.disabled {
        cursor: auto;
        pointer-events: none;
    }
`
const NormalLink = styled(Link)`
    ${RoutLink};

    &.selected {
        color: #afafaf;
        cursor: auto;
        pointer-events: none;
    }
    &.disabled {
        cursor: auto;
        pointer-events: none;
    }
`
const BlankSpace = styled.div`
    float: left;
    height: 50px;
    position: relative;
    width: 100%
`
const Heading1 = styled.div`
    font-size: 22px;
    font-weight: 500;
`
const Heading2 = styled.div`
    font-family: 'TerminaW05-Bold';
    font-size: 16px;
    font-weight: 700;
`
const Heading3 = styled.div`
    font-size: 16px;
    font-weight: 700;
`
const Heading4 = styled.div`
    font-size: 40px;
    font-weight: 100;
`
const Heading5 = styled.div`
    font-size: 45px;
    font-weight: 100;
`
const PlainText = styled.div`
    font-size: 16px;
`
const Office = css`
    float: left;
    position: relative;
    width: 50%;

    * {
        padding: 9px 0 0;

        &.morePadding {
            padding: 30px 0 0;
        }
        &.lessPadding {
            padding: 5px 0 0;
        }
        &.noPadding {
            padding: 0;
        }
        &:not(img) {
            float: left;
            position: relative;
            width: 100%;
        }
    }

    @media screen and (max-width: 520px) {
        width: 100%;
        text-align: left;
    }
`
const Office1 = styled.div`
    ${Office};
    padding-right: 2rem;
    text-align: left;

    .mainHeading {
        opacity: 0;
    }

    @media screen and (max-width: 520px) {
        padding-right: 0;

        .mainHeading {
            opacity: 100;
        }
    }
`
const Office2 = styled.div`
    ${Office};
    padding-left: 2rem;
    text-align: right;

    @media screen and (max-width: 520px) {
        padding-left: 0;

        .mainHeading {
            opacity: 0;
        }
    }
`
const Office3 = styled.div`
    ${Office};
    padding-left: 2rem;
    text-align: right;
    .mainHeading {
        opacity: 0;
    }
    @media screen and (max-width: 520px) {
        padding-left: 0;

        .mainHeading {
            opacity: 0;
        }
    }
`
const topMargin2PX = {
    marginTop: "2px"
}
const inheritDisplay = {
    display: 'inherit'
}
const Footer = ({pageID}) => {
    const [isVisible, setVisibility] = useState(false);
    const ref = useRef(null);
    const handleScroll = () => {
        // if (ref.current) {
            const maxHeight = document.body.scrollHeight - 15;
            const scrollPos = Math.round(window.pageYOffset + window.innerHeight);
            if (scrollPos >= maxHeight) {
                setVisibility(true);
            }
        // }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', () => handleScroll);
            setVisibility(false);
            setIndiaTime('');
            setCanadaTime('');
            setUKTime('');
        };
    }, []);
    const [currentIndiaTime, setIndiaTime] = useState('');
    const [currentCanadaTime, setCanadaTime] = useState('');
    const [currentUKTime, setUKTime] = useState('');

    const updateTime = () => {
        let date = new Date();
        let hours = date.getHours();
        let min = date.getMinutes();
        let sec = date.getSeconds();
        let time = (hours < 10 ? '0'+hours : hours) + ':' + (min < 10 ? '0'+min : min) + ':' + (sec < 10 ? '0'+sec : sec);

        setIndiaTime(time);

        let utc = date.getTime() + (date.getTimezoneOffset() * 60000);
        let canadaDate = new Date(utc + (3600000*-4));

        hours = canadaDate.getHours();
        min = canadaDate.getMinutes();
        sec = canadaDate.getSeconds();
        time = (hours < 10 ? '0'+hours : hours) + ':' + (min < 10 ? '0'+min : min) + ':' + (sec < 10 ? '0'+sec : sec);

        setCanadaTime(time);

        let Gtc = date.getTime() + (date.getTimezoneOffset() * 60000);
        let UKDate = new Date(Gtc + (3600000));

        hours = UKDate.getHours();
        min = UKDate.getMinutes();
        sec = UKDate.getSeconds();
        time = (hours < 10 ? '0'+hours : hours) + ':' + (min < 10 ? '0'+min : min) + ':' + (sec < 10 ? '0'+sec : sec);

        setUKTime(time);
    }

    setInterval(updateTime, 1000);
    
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' 
        });
    };
    
    return (
        <>
            <FooterSection className={Styles.standardSection}>
                <FooterWrapper className={Styles.standardWrapper} style={inheritDisplay}>
                    <ClientLogo src={ClientLogoImg} />
                    <ClientSlogan>your digital partner</ClientSlogan>
                </FooterWrapper>
                <Trickle onClick={scrollToTop} />
            </FooterSection>
            <FooterSection className={Styles.standardSection} style={topMargin2PX}>
                <FooterWrapper className={Styles.standardWrapper} style={inheritDisplay}>
                    <BlockOne>
                        <PagesList>
                            <Heading1 style={{opacity: 0}}>Our Offices</Heading1>
                            <BoldedLink to="/digital-learning" key="2" className={(pageID+1 === 2 ? ' selected' : '')}>Digital Learning</BoldedLink>
                            <BoldedLink to="/design-experience" key="3" className={(pageID+1 === 3 ? ' selected' : '')}>Design &amp; Experience</BoldedLink>
                            <BoldedLink to="/product-development" key="4" className={(pageID+1 === 4 ? ' selected' : '')}>Product Development</BoldedLink>
                            <BlankSpace />
                            <NormalLink to="#" key="7" className='disabled'>Careers at Arize</NormalLink>
                            <NormalLink to="/about-us" key="5" className={(pageID+1 === 5 ? ' selected' : '')}>About Us</NormalLink>
                            <NormalLink to="/contact-us" key="6" className={(pageID+1 === 6 ? ' selected' : '')}>Contact Us</NormalLink>
                            <BlankSpace />
                            <LinksInARow>
                                <NormalLink to="#" key="8" className='disabled'>Privacy Policy</NormalLink>
                                <NormalLink to="#" key="9" className='disabled'>Cookies</NormalLink>
                                <NormalLink to="#" key="10" className='disabled'>Terms of Use</NormalLink>
                            </LinksInARow>
                        </PagesList>
                        <OfficesList>
                            <Office1>
                                <Heading1 className='mainHeading'>Our Offices</Heading1>
                                <StyledImage src={Office1IconImg} />
                                <Heading2>India</Heading2>
                                <PlainText className='lessPadding'>Regus, Sixth Floor, Jaipur Centre, Jaipur, India 302018</PlainText>
                                <PlainText className='morePadding'>&nbsp;</PlainText>
                                <PlainText className='morePadding'>403, Platinum Square, Viman Nagar, Pune, India 411014</PlainText>
                                <Heading3 className='morePadding'>Phone</Heading3>
                                <PlainText className='lessPadding'>+91 8209 814 322</PlainText>
                                <Heading4 className='morePadding'>{currentIndiaTime}</Heading4>
                            </Office1>
                            <Office2>
                                <Heading1 className='mainHeading'>Our Offices</Heading1>
                                <StyledImage src={Office2IconImg} />
                                <Heading2>Canada</Heading2>
                                <PlainText className='lessPadding'>3080 Yonge Street, Suite 6060, Toronto M4N 3N1</PlainText>
                                <Heading3 className='morePadding'>Phone</Heading3>
                                <PlainText className='lessPadding'>+1 718 509 6711</PlainText>
                                <Heading4 className='morePadding'>{currentCanadaTime}</Heading4>
                            </Office2>
                            <Office3>
                                <Heading1 className='mainHeading'>Our Offices</Heading1>
                                <StyledImage src={Office3IconImg} />
                                <Heading2>UK</Heading2>
                                <PlainText className='lessPadding'>71-75 Shelton Street,</PlainText>
                                <PlainText className='lessPadding'>London, England</PlainText>
                                <PlainText className='lessPadding'>WC2H 9JQ</PlainText>
                                <PlainText className='lessPadding'>+44 (0) 203 861 7905</PlainText>
                                <Heading4 className='morePadding'>{currentUKTime}</Heading4>
                            </Office3>
                        </OfficesList>
                    </BlockOne>
                    <BlockTwo>
                        <PlainText className='morePadding'>Copyright © 2021 Arize Digital. All rights reserved.</PlainText>
                    </BlockTwo>
                    <BlockThree>
                        <Heading5 style={{color: '#c7c7c7', paddingBottom: '67px'}} ref={ref}>Learning - Design - Product</Heading5>
                        <StyledImage src={FooterAvatarImg} ref={ref} style={ isVisible ? {bottom: 0} : {}} />
                    </BlockThree>
                </FooterWrapper>
            </FooterSection>
        </>
    )
}

export default Footer
import React from 'react'
import styled from 'styled-components'
import Styles from '../../css/styles.module.css'
import '../../css/expertise.css'
import ImageOne from "../../../images/expertise1.png";
import ImageTwo from "../../../images/expertise2.png";
import ImageThree from "../../../images/expertise3.png";
// import { Link } from 'react-router-dom'

const ExpertiesSection = styled.section`
    // background: url('http://arize.co.in/arize-beta/wp-content/uploads/2021/06/arize_robo_home.png') no-repeat;
    // background-position-x: 0%;
    // background-position-y: 0%;
    // background-repeat: no-repeat;
    // background-position: left -30px bottom 16% !important;
    // background-color:  none;
    float: left;
    position: relative;
    text-align: center;
    width: 100%;

    @media screen and (max-width: 1049px) {
        background: none;
    }
`;

const ExpertiesWrapper = styled.div`
    overflow: hidden;
    padding: 50px 0 30px;
    position: relative;
    text-align: center;
    width: 100%;
`;

const BlockOne = styled.div`
    margin: 0 auto 80px auto;
    max-width: 760px;

    @media screen and (max-width: 768px) {
        padding: 0 15px;
    }
`
const BlockOneTitle = styled.div``
const BlockOneBody = styled.div``
const BlockTwo = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    max-width: 920px;

    @media screen and (max-width: 1199px) {
        max-width: inherit;
    }
    @media screen and (max-width: 991px) {
        display: block;
    }
`
// const BlockThree = styled.div`
//     align-items: center;
//     display: flex;
//     justify-content: center;
//     margin: 50px auto;
//     max-width: 920px;

//     @media screen and (max-width: 1199px) {
//         margin: 120px auto;
//         max-width: inherit;
//     }
//     @media screen and (max-width: 991px) {
//         display: block;
//     }
// `
const BlockFour = styled.div`
    margin: 115px auto 0 auto;
    max-width: 920px;

    #title {
        color: #3d3d3d;
        margin-bottom: 15px;
    }
    #body {
        color: #3d3d3d;
        margin-bottom: 0;
    }
`
const ExpertiesItem = styled.div`
    margin-right: 15px;
    max-width: 320px;
    padding: 0 15px 15px;
    width: calc(20% + 60px);

    &:last-child {
        margin-right: 0;
    }

    @media screen and (max-width: 1199px) {
        max-width: inherit;
        width: calc(100% - 30px / 3);
    }
    @media screen and (max-width: 991px) {
        margin: 0 auto !important;
        max-width: 320px;
    }
`
// const GraphicItem = styled.div`
//     background: #9088cd;
//     height: 170px;
//     margin-right: 15px;
//     padding: 15px;
//     width: calc(20% + 60px);

//     &:last-child {
//         margin-right: 0;
//     }

//     @media screen and (max-width: 1199px) {
//         width: calc(100% - 30px / 3);
//     }
//     @media screen and (max-width: 991px) {
//         margin: 0 0 15px 0;
//         width: 100%;
//     }
// `
const Icon = styled.img`

`
const Title = styled.div`
    font-size: 1.4375rem;
    margin-bottom: 25px;
`
const Body = styled.div`
    font-size: 18px;
    margin-bottom: 25px;

    @media screen and (max-width: 1022px) {
        font-size: 16px;
    }
    @media screen and (max-width: 798px) {
        font-size: 14px;
    }
`
// const NavLink = styled(Link)`
//     font-size: 18px;
//     margin: 0 auto;

//     @media screen and (max-width: 1022px) {
//         font-size: 16px;
//     }
//     @media screen and (max-width: 798px) {
//         font-size: 14px;
//     }
// `

const Experties = () => {
  return (
    <>
        <ExpertiesSection >
            <ExpertiesWrapper >
                <BlockOne className='blockOne'>
                    <BlockOneTitle className={'title '+Styles.standardTitle}>Services &amp; Expertise</BlockOneTitle>
                    <BlockOneBody className={Styles.standardBody}>We thrive at the intersection of design, technology and the user experience and help brands navigate showing up at their best. Our range of services include design, branding, user experience, product development, digital learning and EdTech.</BlockOneBody>
                </BlockOne>
                <BlockTwo className='blockTwo'>
                    <ExpertiesItem>
                        <Icon src={ImageOne} />
                        <Title className={'title '+Styles.standardTitle}>Digital Learning</Title>
                        <Body className={'body '+Styles.standardBody}>With more then fifteen years of experience in the learning landscape we have one of the widest range of offerings for digital learning requirements. From content to platforms, we have covered it all.</Body>
                    </ExpertiesItem>
                    <ExpertiesItem>
                        <Icon src={ImageTwo} />
                        <Title className={'title '+Styles.standardTitle}>Design &amp; Experience</Title>
                        <Body className={'body '+Styles.standardBody}>To understand the user is the the most essential part. And design and aesthetics is another. We at Arize offer Design and User Experience services that helps you make a niche for your product.</Body>
                    </ExpertiesItem>
                    <ExpertiesItem>
                        <Icon src={ImageThree} />
                        <Title className={'title '+Styles.standardTitle}>Product Development</Title>
                        <Body className={'body '+Styles.standardBody}>From Ecommerce stores to Web portals, websites, apps and landing pages, Arize Digital is your one-stop digital partner. We are a team of passionate engineers and our clients are our top priorities.</Body>
                    </ExpertiesItem>
                </BlockTwo>
                {/* <BlockThree>
                    <GraphicItem><Icon src={ImageOne} /></GraphicItem>
                    <GraphicItem><Icon src={ImageTwo} /></GraphicItem>
                    <GraphicItem><Icon src={ImageThree} /></GraphicItem>
                </BlockThree> */}
                <BlockFour>
                    <div id='title' className={Styles.standardTitle}>Technology</div>
                    <div id='body' className={Styles.standardBody}>Technologies and methodologies we use</div>
                </BlockFour>
            </ExpertiesWrapper>
        </ExpertiesSection>
    </>
  )
}

export default Experties
import React, { useEffect } from 'react';
import styled from 'styled-components'
import '../css/digital-learning.css';
import Footer from '../layouts/Footer';
import {Link } from "react-router-dom";
import ScrollToTopOnMount from '../ScrollToTop';
import IntersectionVisible from 'react-intersection-visible';
import Icon1 from '../../images/dl_icon01.png';
import Icon2 from '../../images/dl_icon02.png';
import Icon3 from '../../images/dl_icon03.png';
import Icon4 from '../../images/dl_icon04.png';
import Icon5 from '../../images/dl_icon05.png';
import Icon6 from '../../images/dl_icon06.png';
import Icon7 from '../../images/dl_icon07.png';
import Icon8 from '../../images/dl_icon08.png';
import Icon9 from '../../images/dl_icon09.png';
import Icon10 from '../../images/dl_icon10.png';
import Icon11 from '../../images/dl_icon11.png';
import Icon12 from '../../images/dl_icon12.png';

const Icon = styled.img`
`

const DigitalLearning = ({setPageID}) => {
    const onShow = ( entries ) => {
        // {
            if(!entries[0].target.classList.contains('animate-in'))
                entries[0].target.classList.add('animate-in');
        // }
    }
    useEffect(() => {
        setPageID(0);

        return () => {
            setPageID(-1);
        };
    }, []);
    return (
        <>
            <ScrollToTopOnMount />
            <div className='digi-top-banner'>
                
            </div>
            <div className='blank-block'>
                {/* <div className='dl-banner-text'>Your learning partner.</div> */}
            </div>
            <div className='app-digital'>
                <div className='app-digital-inner '>
                    <div className='app-digital-title'>Digital Learning.</div>
                    <div className='app-digital-body'>The team at Arize with more than 15 years of experience in digital learning development is your ideal learning partner for all your L&amp;D needs.</div>
                    <div className='app-digital-body2'>Give your internal and external audiences the knowledge they need to succeed. With a wide range of offerings we cover a variety of learning solutions, from rapid solutions to fully customised content and ready-to-use t customised LMS, all as per your needs.</div>
                </div>
                <div className='app-digital-top-inner'>
                    <div className='digital-purple-title'>
                        <div className='digital-purple-title-inner'>Content Development</div>
                    </div>
                    <IntersectionVisible options={{threshold: 1}} className='box-container box1-container' onShow={ e => onShow( e ) }>
                        <div className='digital-box digital-box1'>
                            <div className='digital-box1-title'>Bespoke/Customised Learning</div>
                            <div className='digital-box1-img'>
                                <Icon src={Icon1} />
                            </div>
                        </div>
                    </IntersectionVisible>
                    <IntersectionVisible options={{threshold: 1}} className='box-container box2-container' onShow={ e => onShow( e ) }>
                        <div className='digital-box digital-box2'>
                            <div className='digital-box2-title'>Video-based Learning</div>
                            <div className='digital-box1-img'>
                                <Icon src={Icon2} />
                            </div>
                        </div>
                    </IntersectionVisible>
                    <IntersectionVisible options={{threshold: 1}} className='box-container box3-container' onShow={ e => onShow( e ) }>
                        <div className='digital-box digital-box2'>
                            <div className='digital-box1-title'>Scenario-based Learning</div>
                            <div className='digital-box1-img'>
                                <Icon src={Icon3} />
                            </div>
                        </div>
                    </IntersectionVisible>
                    <IntersectionVisible options={{threshold: 1}} className='box-container box4-container' onShow={ e => onShow( e ) }>
                        <div className='digital-box digital-box1'>
                            <div className='digital-box2-title'>Mobile-first learning</div>
                            <div className='digital-box1-img'>
                                <Icon src={Icon4} />
                            </div>
                        </div>
                    </IntersectionVisible>
                    <IntersectionVisible options={{threshold: 1}} className='box-container box5-container' onShow={ e => onShow( e ) }>
                        <div className='digital-box digital-box1'>
                            <div className='digital-box1-title'>Micro Learning</div>
                            <div className='digital-box1-img'>
                                <Icon src={Icon5} />
                            </div>
                        </div>
                    </IntersectionVisible>
                    <IntersectionVisible options={{threshold: 1}} className='box-container box6-container' onShow={ e => onShow( e ) }>
                        <div className='digital-box digital-box2'>
                            <div className='digital-box2-title'>Rapid Learning</div>
                            <div className='digital-box1-img'>
                                <Icon src={Icon6} />
                            </div>
                        </div>
                    </IntersectionVisible>
                    <IntersectionVisible options={{threshold: 1}} className='box-container box7-container' onShow={ e => onShow( e ) }>
                        <div className='digital-box digital-box2'>
                            <div className='digital-box2-title'>Accessible Content Development</div>
                            <div className='digital-box1-img'>
                                <Icon src={Icon7} />
                            </div>
                        </div>
                    </IntersectionVisible>
                    <IntersectionVisible options={{threshold: 1}} className='box-container box8-container' onShow={ e => onShow( e ) }>
                        <div className='digital-box digital-box1'>
                            <div className='digital-box1-title'>Localisation</div>
                            <div className='digital-box1-img'>
                                <Icon src={Icon8} />
                            </div>
                        </div>
                    </IntersectionVisible>
                    <IntersectionVisible options={{threshold: 1}} className='box-container box9-container' onShow={ e => onShow( e ) }>
                        <div className='digital-box digital-box1'>
                            <div className='digital-box1-title'>Flash-to-HTML</div>
                            <div className='digital-box1-img'>
                                <Icon src={Icon9} />
                            </div>
                        </div>
                    </IntersectionVisible>
                    <IntersectionVisible options={{threshold: 1}} className='box-container box10-container' onShow={ e => onShow( e ) }>
                        <div className='digital-box digital-box2'>
                            <div className='digital-box2-title'>SCORM Wrapping</div>
                            <div className='digital-box1-img'>
                                <Icon src={Icon10} />
                            </div>
                        </div>
                    </IntersectionVisible>
                    <div className='digital-purple-title2'>
                        <div className='digital-purple-title2-inner'>LMS &amp; LXP</div>
                    </div>
                    <IntersectionVisible options={{threshold: 1}} className='box-container box11-container' onShow={ e => onShow( e ) }>
                        <div className='digital-box digital-box1'>
                            <div className='digital-box1-title'>Ready-to-use LMS</div>
                            <div className='digital-box1-img'>
                                <Icon src={Icon11} />
                            </div>
                        </div>
                    </IntersectionVisible>
                    <IntersectionVisible options={{threshold: 1}} className='box-container box12-container' onShow={ e => onShow( e ) }>
                        <div className='digital-box digital-box2'>
                            <div className='digital-box2-title'>Customised LMS</div>
                            <div className='digital-box1-img'>
                                <Icon src={Icon12} />
                            </div>
                        </div>
                    </IntersectionVisible>
                </div>
                <div className='app-digital-middle-inner'>
                    <div className='app-digital-middle-text'></div>
                    <div className='getTouch-outer'><Link to="#" onClick={(e) => {
                        window.location.href = 'mailto:hello@arize.co.in';
                        e.preventDefault();
                    }}>Let’s talk about your learning project?</Link></div>
                </div>
            </div>
                <div className='app-approach-bottom'>
                    <div className='app-approach-bottom-inner'>
                    <div className='app-approach-bottom-inner-top'>
                        <div className='app-approach-bottom-text'><b>Services</b><br/>Explore our services.</div>
                        <div className='app-approach-bottom-box'>
                            <div className='app-approach-bottom-box1'>
                                <div className='app-approach-bottom-box1-trow'>Approach</div>
                                <div className='app-approach-bottom-box1-blef'><Link to="#">Explore</Link></div>
                                <div className='app-approach-bottom-box0-brig'></div>
                            </div>

                            <div className='app-approach-bottom-box1'>
                                <div className='app-approach-bottom-box1-trow'>Design &amp; Experience</div>
                                <div className='app-approach-bottom-box1-blef'><Link to="/design-experience">Explore</Link></div>
                                <div className='app-approach-bottom-box2-brig'></div>
                            </div>

                            <div className='app-approach-bottom-box1'>
                                <div className='app-approach-bottom-box1-trow'>Product Development</div>
                                <div className='app-approach-bottom-box1-blef'><Link to="/product-development">Explore</Link></div>
                                <div className='app-approach-bottom-box3-brig'></div>
                            </div>
                        </div>
                    </div>
                        <div className='app-call-us'>Have a <b>question?</b><br/><a href="mailto:jay@arize.co.in"><b>Write to us</b></a> or <b>Call</b> us!</div>  
                    </div>
                    
                </div>
            <Footer pageID={1} />
        </>
    )
}

export default DigitalLearning
